<template>
  <router-link
    v-if="showRoute"
    v-slot="{ isActive }"
    :to="{ name: routeName }"
    class="nav-item-link"
  >
    <li
      :class="{
        'is-active': !disableActiveStyling && isActive,
        'moonboarding-active': moonboardingActive,
      }"
      class="nav-item"
      @click="onClick"
    >
      <section class="icon" :class="{ 'active-icon': isActive }">
        <slot name="icon"> </slot>
      </section>
      <section class="text">
        <slot name="text"></slot>
      </section>
      <slot></slot>
    </li>
  </router-link>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import type { RouteName } from "@/router/routeName";
import routeNameToMeta from "@/router/routeNameToMeta";
import useUserPermissions from "@/functional/hooks/useUserPermissions";

const props = withDefaults(
  defineProps<{
    routeName: RouteName;
    disableActiveStyling?: boolean;
    moonboardingActive?: boolean;
  }>(),
  {
    moonboardingActive: false,
  },
);

const { hasPermissions } = useUserPermissions();

const emit = defineEmits(["click"]);

const showRoute = computed(() => {
  const requiredPermissions = routeNameToMeta(
    props.routeName,
  ).requiredPermissions;

  return hasPermissions(requiredPermissions);
});

const onClick = (e: Event) => {
  emit("click", e);
};
</script>
<style scoped>
.nav-item-link:focus-visible :deep(.nav-item) {
  box-shadow: inset 0 0 0 3px var(--color-focus);
}

.icon {
  stroke: var(--vt-c-grey-5);
}

.active-icon :deep(rect) {
  stroke: rgb(0, 0, 0);
}

.active-icon :deep(path) {
  stroke: rgb(0, 0, 0);
}
.text {
  width: 100%;
}
</style>
