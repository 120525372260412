export enum RouteName {
  // /login
  AUTH = "auth",
  LOGIN = "login",
  USER_ONBOARD = "userOnboard",
  PASSWORD_RESET_REQUEST = "passwordResetRequest",
  PASSWORD_RESET = "passwordReset",
  // /
  DASHBOARD = "dashboard",
  // /organisations
  ORGANISATIONS = "organisations",
  ORGANISATION_LIST = "organisationsList",
  ORGANISATION_NEW = "organisationsNew",
  ORGANISATION_EDIT = "organisationsEdit",
  SCENARIO_TREES = "scenarioTrees",
  SUITE_STATE = "suiteState",
  // / organisations/suiteState
  SUITE_STATE_LIST = "suiteStateList",
  SUITE_STATE_UPDATE = "suiteStateUpdate",
  // /users/id
  USER = "user",
  USER_SUBMIT_SCORE = "user-submit-score",
  // /users
  USERS = "users",
  ADD_USERS = "add-users",
  // Non-nested routes:
  TRENDS_ANALYTICS = "trends-analytics",
  LEARNING_MATERIALS = "learning-materials",
  REPORT_GENERATOR = "report-generator",
  MY_PROFILE = "my-profile",
  MY_LEARNING = "my-learning",
  ORGANISATION_LOGIN = "organisationLogin",
  NOT_FOUND = "notFound",
  PUBLIC_SHOP = "public-shop",
  AUDIT = "audit",
  LEADERBOARD = "leaderboard",
  MOONBOARDING = "moonboarding",
  WELCOME = "welcome",
  // /platformIssues
  PLATFORM_ISSUES = "platform-issues",
}

export type RouteNameWithMeta = RouteName;
