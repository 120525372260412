<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="28"
    viewBox="0 0 22 28"
    fill="none"
  >
    <path
      d="M16.7318 6.37681C16.7318 9.27604 14.1718 11.7536 10.8508 11.7536C7.52973 11.7536 4.96973 9.27604 4.96973 6.37681C4.96973 3.47758 7.52973 1 10.8508 1C14.1718 1 16.7318 3.47758 16.7318 6.37681Z"
      fill="white"
      stroke="#A5AEC5"
      stroke-width="2"
    />
    <path
      d="M19.7015 26.6667H2C1.44772 26.6667 1 26.2189 1 25.6667V20.259L6.22109 16.5541C8.99394 14.5865 12.7076 14.5865 15.4804 16.5541L20.7015 20.259V25.6667C20.7015 26.2189 20.2538 26.6667 19.7015 26.6667Z"
      fill="white"
      stroke="#A5AEC5"
      stroke-width="2"
    />
  </svg>
</template>
