<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="22"
    viewBox="0 0 33 22"
    fill="none"
  >
    <path
      d="M19.578 6.79295C19.578 8.65668 18.0451 10.1884 16.1283 10.1884C14.2116 10.1884 12.6787 8.65668 12.6787 6.79295C12.6787 4.92923 14.2116 3.39752 16.1283 3.39752C18.0451 3.39752 19.578 4.92923 19.578 6.79295Z"
      fill="white"
      stroke-width="2"
    />
    <path
      d="M29.4612 3.46307C29.4612 4.79153 28.3371 5.92614 26.878 5.92614C25.419 5.92614 24.2949 4.79153 24.2949 3.46307C24.2949 2.1346 25.419 1 26.878 1C28.3371 1 29.4612 2.1346 29.4612 3.46307Z"
      fill="white"
      stroke-width="2"
    />
    <path
      d="M7.96214 3.46307C7.96214 4.79153 6.83803 5.92614 5.37902 5.92614C3.92001 5.92614 2.7959 4.79153 2.7959 3.46307C2.7959 2.1346 3.92001 1 5.37902 1C6.83803 1 7.96214 2.1346 7.96214 3.46307Z"
      fill="white"
      stroke-width="2"
    />
    <path
      d="M21.4988 20.7784H10.7588C10.2065 20.7784 9.75879 20.3307 9.75879 19.7784V16.3617L11.3027 15.1939C14.1577 13.0344 18.0998 13.0344 20.9548 15.1939L22.4988 16.3617V19.7784C22.4988 20.3307 22.0511 20.7784 21.4988 20.7784Z"
      fill="white"
      stroke-width="2"
    />
    <path
      d="M30.2578 13.8523H23.499C22.9467 13.8523 22.499 13.4046 22.499 12.8523V10.5398C25.0417 8.44172 28.7151 8.44172 31.2578 10.5398V12.8523C31.2578 13.4046 30.81 13.8523 30.2578 13.8523Z"
      fill="white"
      stroke-width="2"
    />
    <path
      d="M8.75874 13.8523H2C1.44772 13.8523 1 13.4046 1 12.8523V10.5398C3.54265 8.44172 7.21609 8.44172 9.75874 10.5398V12.8523C9.75874 13.4046 9.31102 13.8523 8.75874 13.8523Z"
      fill="white"
      stroke-width="2"
    />
  </svg>
</template>
