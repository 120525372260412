<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="29"
    viewBox="0 0 31 29"
    fill="none"
  >
    <rect
      x="1"
      y="8.22656"
      width="6.89925"
      height="19.6818"
      rx="1"
      fill="white"
      stroke-width="2"
    />
    <rect
      x="11.679"
      y="15.4551"
      width="6.89925"
      height="12.4545"
      rx="1"
      fill="white"
      stroke-width="2"
    />
    <rect
      x="22.3579"
      y="1"
      width="6.89925"
      height="26.9091"
      rx="1"
      fill="white"
      stroke-width="2"
    />
  </svg>
</template>
