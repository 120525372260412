<template>
  <nav class="nav">
    <ul class="moon-nav-first">
      <div class="moonhub-brand-container">
        <icon-black-moon class="icon" />
        <div class="nav-text">MOONHUB</div>
      </div>
    </ul>
    <div class="moon-nav-middle">
      <moon-nav-item :route-name="RN.ORGANISATIONS">
        <template #icon>
          <icon-organisations class="icon fill" />
        </template>
        <template #text>
          <div class="nav-text">Organisations</div>
        </template>
      </moon-nav-item>
      <moon-nav-item
        :route-name="RN.USERS"
        :moonboarding-active="urlHash == SlideHash.Learners"
      >
        <template #icon>
          <icon-people class="icon" />
        </template>
        <template #text>
          <div class="nav-text">Learners</div>
        </template>
      </moon-nav-item>
      <moon-nav-item
        :route-name="RN.LEADERBOARD"
        :moonboarding-active="urlHash == SlideHash.Leaderboard"
      >
        <template #icon>
          <icon-leaderboard class="icon fill" />
        </template>
        <template #text>
          <div class="nav-text">Leaderboard</div>
        </template>
      </moon-nav-item>
      <moon-nav-item
        :route-name="RN.MY_LEARNING"
        :moonboarding-active="urlHash == SlideHash.MyLearning"
      >
        <template #icon>
          <icon-book class="icon" />
        </template>
        <template #text>
          <div class="nav-text">My Learning</div>
        </template>
      </moon-nav-item>
      <moon-nav-item
        :route-name="RN.TRENDS_ANALYTICS"
        :moonboarding-active="urlHash == SlideHash.TrendsAndAnalytics"
      >
        <template #icon>
          <icon-analytics class="icon" />
        </template>
        <template #text>
          <div class="nav-text">Trends & Analytics</div>
        </template>
      </moon-nav-item>
      <moon-nav-item :route-name="RN.PLATFORM_ISSUES">
        <template #icon>
          <icon-bug class="icon" />
        </template>
        <template #text>
          <div class="nav-text">Platform Issues</div>
        </template>
      </moon-nav-item>
    </div>
    <ul class="moon-nav-last">
      <button
        v-if="showReportBtn"
        class="generate-report-btn"
        :class="{
          'moonboarding-active-btn': urlHash == SlideHash.ReportGenerator,
        }"
        @click="onGenerateReportClicked"
      >
        Generate Report
      </button>
      <moon-nav-item
        :route-name="RN.MY_PROFILE"
        :moonboarding-active="urlHash == SlideHash.MyProfile"
      >
        <template #icon>
          <icon-profile class="icon" />
        </template>
        <template #text>
          <div class="nav-text">My Profile</div>
        </template>
      </moon-nav-item>
      <moon-nav-item :route-name="RN.MOONBOARDING">
        <template #icon>
          <icon-moonboarding class="icon" />
        </template>
        <template #text>
          <div class="nav-text">Moonboarding</div>
        </template>
      </moon-nav-item>
      <moon-nav-item :route-name="RN.LOGIN" @click="onLogout">
        <template #icon>
          <icon-logout />
        </template>
        <template #text>
          <div class="nav-text">Logout</div>
        </template>
      </moon-nav-item>
    </ul>
  </nav>
  <nav class="nav-hamburger">
    <div class="navbar">
      <div class="container nav-container">
        <input class="checkbox" type="checkbox" @click="toggleMenu" />
        <div class="hamburger-lines">
          <span
            class="line line1"
            :class="{
              'line1-open': isNavMenuOpen,
            }"
          ></span>
          <span
            class="line line2"
            :class="{
              'line2-open': isNavMenuOpen,
            }"
          ></span>
          <span
            class="line line3"
            :class="{
              'line3-open': isNavMenuOpen,
            }"
          ></span>
        </div>
        <icon-black-moon class="brand-icon" />
        <div
          class="menu-items"
          :class="{ 'menu-items-open': isNavMenuOpen }"
          @click="toggleMenu"
        >
          <moon-nav-item :route-name="RN.ORGANISATIONS">
            <template #icon>
              <icon-organisations class="icon" />
            </template>
            <template #text>
              <div class="nav-text-container">
                <div class="nav-text">Organisations</div>
                <icon-chevron-right />
              </div>
            </template>
          </moon-nav-item>
          <moon-nav-item :route-name="RN.USERS">
            <template #icon>
              <icon-people class="icon" />
            </template>
            <template #text>
              <div class="nav-text-container">
                <div class="nav-text">Learners</div>
                <icon-chevron-right />
              </div>
            </template>
          </moon-nav-item>
          <moon-nav-item
            :route-name="RN.MY_LEARNING"
            :moonboarding-active="urlHash == SlideHash.MyLearning"
          >
            <template #icon>
              <icon-book class="icon" />
            </template>
            <template #text>
              <div class="nav-text-container">
                <div class="nav-text">My Learning</div>
                <icon-chevron-right />
              </div>
            </template>
          </moon-nav-item>
          <moon-nav-item :route-name="RN.REPORT_GENERATOR">
            <template #icon>
              <icon-report class="icon" />
            </template>
            <template #text>
              <div class="nav-text-container">
                <div class="nav-text generate-report-text">Generate Report</div>
                <icon-chevron-right />
              </div>
            </template>
          </moon-nav-item>
          <moon-nav-item :route-name="RN.MY_PROFILE">
            <template #icon>
              <icon-profile class="icon" />
            </template>
            <template #text>
              <div class="nav-text-container">
                <div class="nav-text">My Profile</div>
                <icon-chevron-right />
              </div>
            </template>
          </moon-nav-item>
          <moon-nav-item :route-name="RN.LOGIN" @click="onLogout">
            <template #icon>
              <icon-logout />
            </template>
            <template #text>
              <div class="nav-text">Logout</div>
            </template>
          </moon-nav-item>
        </div>
      </div>
    </div>
  </nav>
</template>
<script lang="ts" setup>
import MoonNavItem from "@/components/nav/MoonNavItem.vue";
import { useAuthStore } from "@/stores/useAuthStore";
import { useRoute, useRouter } from "vue-router";
import { computed, ref, watch } from "vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RouteName } from "@/router/routeName";
import IconLogout from "@/components/icons/nav/IconLogout.vue";
import IconPeople from "@/components/icons/nav/IconPeople.vue";
import IconOrganisations from "@/components/icons/nav/IconOrganisations.vue";
import IconBook from "../icons/IconBook.vue";
import IconProfile from "../icons/IconProfile.vue";
import useUserPermissions from "@/functional/hooks/useUserPermissions";
import routeNameToMeta from "@/router/routeNameToMeta";
import IconBlackMoon from "@/components/icons/nav/IconBlackMoon.vue";
import IconChevronRight from "../icons/IconChevronRight.vue";
import IconReport from "../icons/nav/IconReport.vue";
import IconAnalytics from "../icons/IconAnalytics.vue";
import IconLeaderboard from "../icons/IconLeaderboard.vue";
import IconMoonboarding from "../icons/IconMoonboarding.vue";
import IconBug from "../icons/IconBug.vue";
import { SlideHash } from "@/functional/moonboardingUrlHashes";
const userStore = useAuthStore();
const router = useRouter();
const RN = RouteName;
const isNavMenuOpen = ref<boolean>(false);

const route = useRoute();
const urlHash = ref<string>(route.hash.replace("#", ""));

watch(
  () => route.hash,
  (newHash) => {
    urlHash.value = newHash.replace("#", "");
  },
);
const onLogout = () => {
  console.log("Logging out");
  userStore.logout(router);
};
const { hasPermissions } = useUserPermissions();
const showReportBtn = computed(() => {
  const requiredPermissions = routeNameToMeta(
    RouteName.REPORT_GENERATOR,
  ).requiredPermissions;

  return hasPermissions(requiredPermissions);
});

const onGenerateReportClicked = () => {
  router.push({
    name: RouteName.REPORT_GENERATOR,
  });
};
const toggleMenu = () => {
  isNavMenuOpen.value = !isNavMenuOpen.value;
};
</script>
<style>
/* Following styles used across components in the nav */
.nav-item {
  padding: 1.6rem 2rem;
  display: flex;
  place-items: center;
  color: var(--vt-c-grey-5);
  fill: var(--vt-c-grey-5);
}

.nav-item > .icon,
.nav-item > .nav-symbol {
  width: 4.2rem;
  max-width: 4.2rem;
  height: 4.2rem;
  max-height: 4.2rem;
  flex-shrink: 0;
  margin: 0;
}

.nav-item > :nth-child(2) {
  padding-left: 1.6rem;
}

.nav-item.is-active {
  background-color: var(--vt-c-grey-4);
  color: var(--color-text);
}

.moonboarding-active {
  background: linear-gradient(270deg, #ededff 0%, #cde1ff 100%);
}
.moonboarding-active-btn {
  transform: scale(1.08);
}

.nav-item:hover {
  background-color: var(--vt-c-grey-4);
  cursor: pointer;
}

.is-active .icon {
  filter: brightness(500%);
}

.is-active .fill {
  fill: black;
}
</style>
<style scoped>
.nav {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  height: 100vh;
  background-color: var(--vt-c-white);
  color: var(--color-text-nav);
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.8s;
  width: 29rem;
  white-space: nowrap;
}
@media only screen and (max-width: 37.5rem) {
  .nav {
    display: none;
  }
}

.nav-text {
  margin-left: 0.5rem;
  transition: all 0.4s;
  font-weight: bold;
}

.nav:hover .nav-text {
  opacity: 1;
}

.moon-nav-last {
  display: flex;
  flex-direction: column;
}
.generate-report-btn {
  margin: 2rem auto;
  background-color: var(--vt-c-purple-1);
  width: 18rem;
  height: 4rem;
  transition: 0.3s all;
}

.generate-report-btn:hover {
  opacity: 0.9;
}

.generate-report-btn:focus {
  outline: var(--color-focus) var(--dimension-focus-ring-width) solid;
}

.moonhub-brand-container {
  padding: 1.6rem 2rem;
  display: flex;
  place-items: center;
  color: var(--vt-c-grey-5);
  fill: var(--vt-c-grey-5);
}

.moonhub-brand-container .icon {
  width: 4em;
  height: 4rem;
}

.moonhub-brand-container > .icon {
  width: 4.2rem;
  max-width: 4.2rem;
  height: 4.2rem;
  max-height: 4.2rem;
  flex-shrink: 0;
  margin: 0;
}

.moon-nav-middle {
  margin: auto 0;
}
.nav-hamburger {
  display: none;
}

@media only screen and (max-width: 62rem) {
  .nav {
    display: none;
  }

  .nav-hamburger {
    display: block;
    position: absolute;
    z-index: 1000;
    background-color: var(--color-background-soft);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  }

  .nav-item-link {
    width: 100%;
  }

  .nav-item-link .nav-text-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .container {
    width: 1050px;
    width: 100%;
    margin: auto;
  }

  .navbar {
    width: 100vw;
    height: 6rem;
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
  }

  .navbar .nav-container li {
    list-style: none;
  }

  .navbar .nav-container a {
    text-decoration: none;
    color: #0e2431;
    font-weight: 500;
    padding: 0.7rem;
  }

  .navbar .nav-container a:hover {
    font-weight: bolder;
  }

  .nav-container {
    display: block;
    position: relative;
    height: 60px;
  }

  .nav-container .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    top: 20px;
    right: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .nav-container .hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 17px;
    right: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #0e2431;
  }

  .nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  .navbar .menu-items {
    padding-top: 6rem;
    height: 100vh;
    width: 100%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.5s ease-in-out;
    background-color: white;
    overflow: hidden;
    justify-content: start;
  }

  .menu-items .nav-item-link {
    width: 100%;
    height: 6rem;
  }

  .logo {
    position: absolute;
    top: -2.2rem;
    right: 15px;
    /* font-size: 1.5rem; */
    font-weight: bold;
    color: #0e2431;
  }

  .nav-container .menu-items-open {
    transform: translateX(0);
  }

  .nav-container .line1-open {
    transform: rotate(45deg);
  }
  .nav-container .line2-open {
    transform: scaleY(0);
  }

  .nav-container .line3-open {
    transform: rotate(-45deg);
  }

  .nav-container .logo-open {
    display: none;
  }
  .generate-report-text {
    color: var(--vt-c-purple-1);
  }

  .nav-hamburger .brand-icon {
    position: absolute;
    z-index: 1000;
    width: 3.5rem;
    height: 3.5rem;
    top: 1.2rem;
    left: 0;
    right: 0;
    margin: auto;
  }
}
</style>
