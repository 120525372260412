<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="29"
    viewBox="0 0 31 29"
    fill="none"
  >
    <path
      d="M1 2C1 1.44772 1.44772 1 2 1H7.35795C11.2239 1 14.358 4.13401 14.358 8V25.2086C14.358 25.7608 13.9102 26.2086 13.358 26.2086H2C1.44772 26.2086 1 25.7608 1 25.2086V2Z"
      fill="white"
      stroke="#A5AEC5"
      stroke-width="2"
    />
    <path
      d="M29.7158 2C29.7158 1.44772 29.2681 1 28.7158 1H23.3579C19.4919 1 16.3579 4.13401 16.3579 8V25.2086C16.3579 25.7608 16.8056 26.2086 17.3579 26.2086H28.7158C29.2681 26.2086 29.7158 25.7608 29.7158 25.2086V2Z"
      fill="white"
      stroke="#A5AEC5"
      stroke-width="2"
    />
    <rect
      x="13.7412"
      y="21.2567"
      width="3.03325"
      height="7.65241"
      rx="1.61663"
    />
  </svg>
</template>
