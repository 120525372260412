<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width="256.000000pt"
    height="256.000000pt"
    viewBox="0 0 256.000000 256.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M685 2545 c-183 -35 -323 -112 -455 -252 -41 -43 -90 -105 -109 -136 -145 -245 -159 -542 -38 -792 40 -82 45 -102 51 -186 24 -332 137 -589 356 -809 425 -428 1097 -479 1579 -120 422 315 593 859 426 1358 -162 484 -592 803 -1113 827 -102 5 -115 8 -197 46 -153 72 -335 95 -500 64z m1046 -519 c236 -112 417 -335 481 -594 30 -120 30 -313 -1 -427 -66 -251 -241 -465 -474 -580 -133 -66 -225 -87 -377 -89 -184 -1 -322 35 -473 125 -84 50 -203 161 -263 246 -41 56 -124 218 -124 240 0 5 39 -4 87 -20 123 -42 306 -49 436 -18 325 77 567 326 632 650 27 131 17 298 -24 419 -17 51 -31 95 -31 97 0 6 72 -20 131 -49z"
      />
    </g>
  </svg>
</template>
a
