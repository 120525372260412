import { defineStore } from "pinia";
import type { AuthedUserModel } from "@/models/authedUser.model";
import type { Router } from "vue-router";
import type {
  UserLoginResponse,
  UserRefreshAccessTokenRequest,
} from "@/functional/api/apiUsers";
import apiUsers from "@/functional/api/apiUsers";
import parseJwt from "@/functional/auth/parseJwt";
import isJwtExpired from "@/functional/auth/isJwtExpired";
import { setSentryUser } from "@/setupSentry";
import type { OrganisationUserModel } from "@/models/organisationUser.model";

export interface AuthState {
  returnToUrl: string | null;
  currentUser: AuthedUserModel | null;
  currentUserPermissions: string[];
}

const localStorageAuthedUserSlug = "user";

export const useAuthStore = defineStore({
  id: "auth",
  state: () =>
    ({
      returnToUrl: null,
      currentUser: null as AuthedUserModel | null,
      currentUserPermissions: [],
    }) as AuthState,
  getters: {
    isAuthenticated: (state) => state.currentUser !== null,
    user: (state) => state.currentUser,
    permissions: (state) => state.currentUserPermissions,
  },
  actions: {
    async hydrateCurrentUserFromStorage(): Promise<AuthedUserModel | null> {
      const storedUserStr = localStorage.getItem(localStorageAuthedUserSlug);
      if (storedUserStr != null) {
        const storedUser = JSON.parse(storedUserStr);
        const token = storedUser.jwtToken;
        const parsedToken = parseJwt(token);
        this.currentUser = storedUser;
        setSentryUser(this.currentUser);
        this.currentUserPermissions = parsedToken?.permission ?? [];
        if (isJwtExpired(parsedToken)) {
          console.info("JWT token has expired");

          return this.refreshToken();
        }
      }

      return Promise.resolve(this.currentUser);
    },
    async login(
      email: string,
      password: string,
      router: Router,
    ): Promise<AuthedUserModel> {
      const user = await apiUsers.login({ email: email, password });
      await this.setUserFromLoginResponse(user);
      await router.push(this.returnToUrl || "/");

      return user;
    },
    async refreshToken(): Promise<AuthedUserModel> {
      console.log("Refreshing tokens");
      if (this.currentUser === null) {
        console.error("Cannot refresh tokens for unset user");
        throw new Error("Cannot refresh tokens for unset user");
      }

      const refreshTokenBody = {
        refreshToken: this.currentUser.userRefreshToken.token,
        userPublicId: this.currentUser.publicId,
      } as UserRefreshAccessTokenRequest;

      return await apiUsers
        .refreshAccessToken(refreshTokenBody)
        .then((user) => this.setUserFromLoginResponse(user))
        .catch((e) => {
          console.error("Failed to refresh tokens", e);
          this.reset();
          throw e;
        });
    },
    async organisationUserLogin(
      orgId: string,
      emailOrOrganisationUserName: string,
      password: string,
      router: Router,
    ): Promise<AuthedUserModel> {
      const user = await apiUsers.organisationLogin({
        organisationPublicId: orgId,
        emailOrOrganisationUserName,
        password,
      });
      await this.setUserFromLoginResponse(user);
      await router.push(this.returnToUrl || "/");

      return user;
    },
    async setUserFromLoginResponse(
      user: UserLoginResponse,
    ): Promise<AuthedUserModel> {
      this.currentUser = user;
      setSentryUser(this.currentUser);
      localStorage.setItem(localStorageAuthedUserSlug, JSON.stringify(user));

      const token = user.jwtToken;
      const parsedToken = parseJwt(token);
      console.log("parsedToken:", parsedToken);
      this.currentUserPermissions = parsedToken?.permission ?? [];

      return this.currentUser;
    },
    async updatePassword(
      token: string,
      singleUseCode: string,
      newPassword: string,
      router: Router,
    ): Promise<AuthedUserModel> {
      console.log("Update password...");
      const parsedToken = parseJwt(token);
      const userId = parsedToken?.["user-id"];
      console.log("userId:", userId);
      if (userId === undefined) {
        throw new Error("User Id not set");
      }
      const user = await apiUsers.updatePassword(
        userId,
        token,
        singleUseCode,
        newPassword,
      );
      await this.setUserFromLoginResponse(user);
      await router.push(this.returnToUrl || "/");

      return user;
    },
    updateCurrentUser(user: AuthedUserModel) {
      this.currentUser = user;
      setSentryUser(this.currentUser);
      localStorage.setItem(localStorageAuthedUserSlug, JSON.stringify(user));
    },
    updateCurrentUserAttributes(user: OrganisationUserModel) {
      if (
        user.publicId !== this.currentUser?.publicId ||
        this.currentUser === null
      ) {
        console.warn(
          `Not updating current user attributes due to an ID mismatch. Previously: ${this.currentUser?.publicId}, Updating to: ${user.publicId}`,
        );
        return;
      }
      console.log("Updating current user attributes");
      const newCurrentUser = {
        ...user,
        ...this.currentUser,
      } as UserLoginResponse;

      this.updateCurrentUser(newCurrentUser);
    },
    reset() {
      this.returnToUrl = null;
      this.currentUser = null;
      setSentryUser(null);
      this.currentUserPermissions = [];
      localStorage.removeItem(localStorageAuthedUserSlug);
    },
    async logout(router: Router) {
      this.reset();

      await router.push("/login");
    },
  },
});
