<template>
  <svg
    width="64px"
    height="64px"
    viewBox="0 0 24.00 24.00"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#000000"
    stroke-width="0.00024000000000000003"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke="#CCCCCC"
      stroke-width="0.24000000000000005"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M22,7H16.333V4a1,1,0,0,0-1-1H8.667a1,1,0,0,0-1,1v7H2a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM7.667,19H3V13H7.667Zm6.666,0H9.667V5h4.666ZM21,19H16.333V9H21Z"
      ></path>
    </g>
  </svg>
</template>
