import { baseAxiosConfig, requests } from "@/functional/api/api";
import type { OrganisationUserModel } from "@/models/organisationUser.model";

import generateUserModel from "@/functional/generators/generateUserModel";
import chainSleep from "@/functional/chainSleep";
import isOffline from "@/functional/isOffline";
import type { ListedUser } from "@/models/listedUser";
import type { ListUsersSortBy } from "@/models/ListUsersSortBy";
import type { InvitationStatus } from "@/models/InvitationStatus";
import { LoginType } from "@/models/LoginType";
import type BulkOnboardResult from "@/models/bulkOnboardingResult.model";

export interface UserLoginRequest {
  email: string;
  password: string;
}

export interface OrganisationUserLoginRequest {
  organisationPublicId: string;
  emailOrOrganisationUserName: string;
  password: string;
}

export type QueryObject = Record<
  string,
  string | string[] | number | undefined | null
>;

export interface ListUserQueryParams extends QueryObject {
  searchTerm?: string;
  roles?: string[];
  nextPageToken?: string | null;
  maxPageSize?: number;
  sortBy?: ListUsersSortBy | null;
  orgId?: string | null;
  invitaitonStatus?: InvitationStatus;
}

export interface UserLoginResponse {
  publicId: string;
  firstName: string;
  lastName: string;
  imageUrl: string | null;
  organisation: {
    publicId: string;
    name: string;
    createdAt: string;
    modifiedAt: string | null;
  };
  createdAt: string;
  modifiedAt: string | null;
  email: string;
  emailConfirmed: boolean;
  organisationUserName: string | null;
  jwtToken: string;
  userRefreshToken: {
    token: string;
  };
}

export interface CreateUserRequest {
  firstName: string;
  lastName: string;
  imageUrl: string | null;
  email: string;
  organisationUserName: string | null;
  managedBy?: string | null;
  rolePublicIds: string[];
}

let login = (details: UserLoginRequest) =>
  requests.post<UserLoginResponse>("users/login", details, {
    ...baseAxiosConfig({ loginType: LoginType.Dashboard }),
  });

const generateUserLoginResponse = () => ({
  publicId: "88f8389f-fc8c-4840-8152-b6531bc19d5d",
  firstName: "Ira",
  lastName: "Montoya",
  imageUrl: null,
  organisation: {
    publicId: "4bb4797f-6f3f-4011-82a8-0375fce59210",
    name: "MOONHUB",
    createdAt: "",
    modifiedAt: null,
  },
  createdAt: "",
  modifiedAt: null,
  email: "ira.montoya@themoonhub.com",
  emailConfirmed: true,
  organisationUserName: "ira.montoya",
  managedBy: null,
  jwtToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyLWlkIjoiYmFhYWFhYWEtYmFiYS1iYWFhLWJhYmEtYmFhYWFhYWFhYWFhIiwicGVybWlzc2lvbiI6WyJsaXN0LXJvbGVzIiwib3JnLWNyZWF0ZSIsIm9yZy1kZWxldGUiLCJvcmctZGV0YWlsIiwib3JnLWVkaXQiLCJvcmctbGlzdCIsIm9yZy12aWV3LXVzZXJzIiwic3VwZXItYWRtaW4iLCJ1cGRhdGUtdXNlci1yb2xlcyIsInVzZXItY3JlYXRlIiwidXNlci1kZWxldGUiLCJ1c2VyLWRldGFpbCIsInVzZXItZWRpdCIsInVzZXItbGlzdCIsInVzZXItbGlzdC11bmRlciJdLCJuYmYiOjE2NTkwMTc1ODYsImV4cCI6MzY1OTAxOTM4NiwiaWF0IjoxNjU5MDE3NTg2fQ.KiVWJ933CN8RM4Mzdt2d2SOjzZfzwAAjJdpFEReHa_4",
  userRefreshToken: { token: "refresh_token" },
});

if (isOffline) {
  login = () =>
    Promise.resolve(generateUserLoginResponse()).then(chainSleep(100, 3000));
}

export interface UserRefreshAccessTokenRequest {
  refreshToken: string;
  userPublicId: string;
}

let refreshAccessToken = (details: UserRefreshAccessTokenRequest) =>
  requests.post<UserLoginResponse>("users/refresh-access-token", details);

if (isOffline) {
  refreshAccessToken = () =>
    Promise.resolve(generateUserLoginResponse()).then(chainSleep(100, 3000));
}

const organisationLogin = (details: OrganisationUserLoginRequest) =>
  requests.post<UserLoginResponse>(`users/organisationLogin`, details);

if (isOffline) {
  login = () =>
    Promise.resolve(generateUserLoginResponse()).then(chainSleep(100, 3000));
}

const passwordResetRequest = (email: string) =>
  requests.post(`users/password-reset`, { email });

let updatePassword = (
  userId: string,
  token: string,
  singleUseCode: string,
  newPassword: string,
) =>
  requests.post<UserLoginResponse>(
    `users/${userId}/set-password`,
    {
      passwordValue: newPassword,
    },
    {
      ...baseAxiosConfig(),
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        singleUseCode,
      },
    },
  );

if (isOffline) {
  updatePassword = () =>
    Promise.resolve({
      publicId: "88f8389f-fc8c-4840-8152-b6531bc19d5d",
      firstName: "Ira",
      lastName: "Montoya",
      imageUrl: null,
      organisation: {
        publicId: "4bb4797f-6f3f-4011-82a8-0375fce59210",
        name: "MOONHUB",
        createdAt: "",
        modifiedAt: null,
      },
      createdAt: "",
      modifiedAt: null,
      email: "mail@themoonhub.com",
      emailConfirmed: true,
      organisationUserName: "ira.montoya",
      managedBy: null,
      jwtToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyLWlkIjoiYmFhYWFhYWEtYmFiYS1iYWFhLWJhYmEtYmFhYWFhYWFhYWFhIiwicGVybWlzc2lvbiI6WyJsaXN0LXJvbGVzIiwib3JnLWNyZWF0ZSIsIm9yZy1kZWxldGUiLCJvcmctZGV0YWlsIiwib3JnLWVkaXQiLCJvcmctbGlzdCIsIm9yZy12aWV3LXVzZXJzIiwic3VwZXItYWRtaW4iLCJ1cGRhdGUtdXNlci1yb2xlcyIsInVzZXItY3JlYXRlIiwidXNlci1kZWxldGUiLCJ1c2VyLWRldGFpbCIsInVzZXItZWRpdCIsInVzZXItbGlzdCIsInVzZXItbGlzdC11bmRlciJdLCJuYmYiOjE2NTkwMTc1ODYsImV4cCI6MzY1OTAxOTM4NiwiaWF0IjoxNjU5MDE3NTg2fQ.KiVWJ933CN8RM4Mzdt2d2SOjzZfzwAAjJdpFEReHa_4",
      userRefreshToken: { token: "refresh_token" },
    }).then(chainSleep(100, 3000));
}

let create: (
  organisationId: string,
  details: CreateUserRequest,
) => Promise<OrganisationUserModel> = (organisationId, details) =>
  requests.post(`organisations/${organisationId}/users`, details);

if (isOffline) {
  create = (organisationId: string, details: CreateUserRequest) =>
    Promise.resolve({
      publicId: "88f8389f-fc8c-4840-8152-b6531bc19d5d",
      firstName: "Ira",
      lastName: "Montoya",
      imageUrl: null,
      organisation: {
        publicId: "4bb4797f-6f3f-4011-82a8-0375fce59210",
        name: "MOONHUB",
        createdAt: "",
        modifiedAt: null,
      },
      createdAt: "",
      modifiedAt: null,
      email: details.email,
      emailConfirmed: true,
      organisationUserName: "ira.montoya",
      managedBy: null,
      roles: [],
    }).then(chainSleep(100, 3000));
}

export interface ListUsersResponse {
  queryCount: number;
  maxPageSize: number;
  nextPageToken: string | null;
  organisationMaxUsers: number;
  previousPageToken: string | null;
  userCount: number;
  users: ListedUser[];
}

let list: (
  organisationId: string,
  queryParams: ListUserQueryParams,
) => Promise<ListUsersResponse> = (organisationId, queryParams) =>
  requests.get<ListUsersResponse>(
    `organisations/${organisationId}/users`,
    queryParams,
  );

if (isOffline) {
  list = async () => {
    const genUsers = await Promise.all(
      Array.from(Array(30)).map(async () => await generateUserModel()),
    );
    const resp = {
      maxPageSize: 30,
      nextPageToken: null,
      previousPageToken: null,
      userCount: 30,
      users: genUsers,
      queryCount: 100,
    } as ListUsersResponse;
    return Promise.resolve(resp).then(chainSleep(100, 3000));
  };
}

export type PutUserRequest = Omit<
  OrganisationUserModel,
  "organisation" | "managedBy"
> & {
  organisation: string;
  managedBy: string | null;
};

let edit: (
  organisationId: string,
  putUserRequest: PutUserRequest,
) => Promise<PutUserRequest> = (organisationId, user) =>
  requests.put<PutUserRequest>(
    `organisations/${organisationId}/users/${user.publicId}`,
    user,
  );

if (isOffline) {
  edit = async (organisationId, user) => {
    return Promise.resolve(user).then(chainSleep(100, 3000));
  };
}

let del: (organisationId: string, userId: string) => Promise<void> = (
  organisationId,
  userId,
) =>
  requests.delete<void>(`organisations/${organisationId}/users/${userId}`, {});
if (isOffline) {
  del = async () => Promise.resolve().then(chainSleep(100, 3000));
}

const assignManager: (
  managerId: string,
  usersIds: string[],
) => Promise<void> = (managerId, usersIds) => {
  return requests.post(`users/${managerId}/assign-manager`, usersIds);
};

let bulkAddUsers: (
  orgId: string,
  groupIds: string[],
  file: FormData,
) => Promise<BulkOnboardResult> = (orgId, groupIds, file) =>
  requests.post(`users/bulk-create`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      orgId,
      groupIds,
    },
    paramsSerializer: { indexes: null },
  });

if (isOffline) {
  bulkAddUsers = async () =>
    Promise.resolve({
      allRowsAddedSuccessfully: false,
      columnHeaders: [],
      rows: [
        {
          rowIndex: 0,
          firstName: "Maria",
          lastName: "Gonzalez",
          email: "maria@example.com",
          roles: ["Learner"],
          username: "mgonzales",
          managerEmail: "manager@example.com",
          errors: [
            {
              columnText: "manager email",
              errorMessages: [
                "The manager with the email 'manager@example.com' does not exist",
              ],
            },
          ],
        },
      ],
    }).then(chainSleep(100, 3000));
}

export default {
  create,
  del,
  edit,
  login,
  refreshAccessToken,
  organisationLogin,
  list,
  passwordResetRequest,
  updatePassword,
  assignManager,
  bulkAddUsers,
};
