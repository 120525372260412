<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 98.41 122.88"
    width="100%"
    height="100%"
  >
    <title>report</title>
    <path
      d="M83.64,122.88H6.89A6.89,6.89,0,0,1,0,116V6.85A6.82,6.82,0,0,1,2,2,6.79,6.79,0,0,1,6.87,0H91.52a6.9,6.9,0,0,1,6.89,6.89V93.08h0v10.36h0v19.41ZM92.48,117V103.31h0V6.89h0a1,1,0,0,0-.28-.68,1,1,0,0,0-.68-.29H6.89a.94.94,0,0,0-1,1V116h0a1,1,0,0,0,.28.67,1,1,0,0,0,.69.29H92.48ZM19,104V97.86h36.1V104L19,104Zm0-16.47V81.39H74.36v6.13L19,87.57ZM74.31,64v6.11H65.62V64ZM58.77,58.57V70.06H50.08V58.57ZM43.24,42.11v28H34.55V42.11ZM27.7,30.59V70.06H19V30.59Zm41.44.27,14.06.05a14.13,14.13,0,0,1-4.19,10A14.33,14.33,0,0,1,77,42.55L69.14,30.86ZM67.7,28.07l-.76-15a.54.54,0,0,1,.52-.56c3.91-.4,8.48,1.48,11.34,4.1A15.62,15.62,0,0,1,83.8,27a.54.54,0,0,1-.5.57l-15,1.08a.54.54,0,0,1-.57-.5.06.06,0,0,0,0,0ZM69.29,27l12.35-1.14c-.9-6.61-5.85-11.38-13-11.92L69.29,27ZM66,30.21l7.54,13a15.07,15.07,0,1,1-9.73-28L66,30.21Z"
    />
  </svg>
</template>
