import type { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import { useAuthStore } from "@/stores/useAuthStore";
import { useToast } from "vue-toast-notification";

const toast = useToast();
const baseAxiosConfig = (params: object = {}): AxiosRequestConfig => ({
  params,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
  // E.g. `?roles=XXX&roles=YYY&roles=ZZZ` rather than `?roles[]=XXX&roles[]=YYY&roles[]=ZZZ`
  // Config will be overwritten for post requests.
  paramsSerializer: { indexes: null },
});
const constructUrl = (url: string): string =>
  `${import.meta.env.VITE_MOON_HUB_WEB_API_URL}/api/${url}`;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const responseIndicatesInvalidAccessToken = (response: AxiosResponse) => {
  // Axios lower-cases all header keys
  const authenticateHeaderKey = "www-authenticate";
  // API Gateway re-maps backend-set headers
  const amazonRemappedAuthenticateHeaderKey =
    "x-amzn-remapped-www-authenticate";
  const invalidTokenHeaderValue = 'error="invalid_token"';
  return (
    response.status === 401 &&
    (response.headers[authenticateHeaderKey].includes(
      invalidTokenHeaderValue,
    ) ||
      response.headers[amazonRemappedAuthenticateHeaderKey].includes(
        invalidTokenHeaderValue,
      ))
  );
};

axios.interceptors.request.use((config) => {
  if (config.headers?.["Authorization"] !== undefined) {
    // Don't overwrite the Authorization header if it has already been manually set for this request
    return config;
  }
  const authStore = useAuthStore();
  if (authStore.user != null && config.headers != undefined) {
    const token = authStore.user.jwtToken;
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const initialRequest = error.config;
    console.log("initial failed request's response:", error.response);

    if (initialRequest._retry) {
      console.error(
        "Refusing to attempt to retry request after previous auth failure",
      );
      return Promise.reject(error);
    }
    if (responseIndicatesInvalidAccessToken(error.response)) {
      console.log(
        "Retrying failed request. First requesting new access token with refresh token",
      );
      initialRequest._retry = true;
      const authStore = useAuthStore();
      return await authStore
        .refreshToken()
        .then(() => {
          console.log(
            "Successful token refresh after previous route access token auth failure",
          );
          const token = authStore.user?.jwtToken;
          if (token === null) {
            console.error("User unset after successful token refresh");
            return Promise.reject(error);
          }
          initialRequest.headers.Authorization = `Bearer ${token}`;
          return axios(initialRequest);
        })
        .catch((e) => {
          console.log(
            "Failed token refresh after previous route access token auth failure",
            e,
          );
          toast.error("Security token has expired, please refresh the page.");
          throw e;
        });
    }
    console.error("Request failed, was not an access token auth failure");
    return Promise.reject(error);
  },
);

const requests = {
  get: <T>(url: string, queryParams: object = {}) =>
    axios
      .get<T>(constructUrl(url), baseAxiosConfig(queryParams))
      .then(responseBody),
  post: <T>(
    url: string,
    body: object | object[],
    axiosConfig: AxiosRequestConfig | undefined = undefined,
  ) =>
    axios
      .post<T>(constructUrl(url), body, axiosConfig ?? baseAxiosConfig())
      .then(responseBody),
  put: <T>(url: string, body: object) =>
    axios.put<T>(constructUrl(url), body, baseAxiosConfig()).then(responseBody),
  delete: <T>(url: string, body: object | object[] | undefined = undefined) => {
    const config =
      body === undefined
        ? baseAxiosConfig()
        : { ...baseAxiosConfig(), data: body };
    return axios.delete<T>(constructUrl(url), config).then(responseBody);
  },
};

export { requests, baseAxiosConfig };
